






















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getNameByEmail } from '@/utils/users';
import workspaceModule from '@/store/modules/workspaceModule';

@Component({

  components: {},
})
export default class TasqItem extends Vue {
  @Prop({ type: String, required: true }) tasqId!: string;

  get tasq(): TasqJob|undefined {
	  if (!tasqsListModule.tasqById(this.tasqId)) {
		  return workspaceModule.tasqById(this.tasqId);
	  }

    return tasqsListModule.tasqById(this.tasqId);
  }

  get fullName(): string {
    if (this.tasq?.reassignedUserName) {
      const initialUserName = getNameByEmail(this.tasq.initialUserName).split(' ');
      const reassignedUsername = getNameByEmail(this.tasq.reassignedUserName).split(' ');
	  if (initialUserName.length > 1 && reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  } if (initialUserName.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}`;
	  } if (reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  }
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}`;
    }
	  return getNameByEmail(this.tasq?.username);
  }

  getSnoozedDate(date) {
    date = new Date(date);
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${month}/${day}/${year}`;
  }

  get percentageForWaitingOn() {
    // console.log(this.tasq.wellName);
    if (this.tasq && this.tasq.waitingOnStartDate && this.tasq.waitingOnEndDate) {
      const startDate: any = new Date(this.tasq.waitingOnStartDate);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      const currentDate: any = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      const endDate: any = new Date(this.tasq?.waitingOnEndDate);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      const differenceStartToCurrent = Math.ceil((Math.abs(currentDate - startDate)) / (1000 * 60 * 60 * 24)) - 1;
      if ((differenceStartToCurrent) < 1) {
        return 0;
      }
      const differenceStartToEnd = Math.ceil(Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24));
      // console.log((differenceStartToCurrent / differenceStartToEnd) * 100);
      const result = (differenceStartToCurrent / differenceStartToEnd) * 100;
      if (result > 100) {
        return 100;
      }
      return result;
    }

    return 0;
    // ts-ignore
  }

  get daysForWaitingOn() {
    // console.log(this.tasq.waitingOnEndDate);
    if (this.tasq && this.tasq.waitingOnStartDate && this.tasq.waitingOnEndDate) {
      // const startDate: any = new Date(this.tasq.waitingOnStartDate);
      const currentDate: any = new Date();
      const endDate: any = new Date(this.tasq?.waitingOnEndDate);
      // const differenceStartToCurrent = Math.ceil(Math.abs(currentDate - startDate) / (1000 * 60 * 60 * 24));
      const differenceStartToEnd = Math.ceil((Math.abs(endDate - currentDate)) / (1000 * 60 * 60 * 24)) - 1;
      // console.log((differenceStartToCurrent / differenceStartToEnd) * 100);
      return differenceStartToEnd < 1 || currentDate > endDate ? 'Ready' : `${differenceStartToEnd}`;
    }

    return 0;
    // ts-ignore
  }

  get tasqScheduledData() {
	  // for (let x = 0; x < workspaceModule.activeWorkspaces.length; x++) {
		//   for (let y = 0; y < workspaceModule.activeWorkspaces[x].Tables.length; y++) {
		// 	  for (let z = 0; z < workspaceModule.activeWorkspaces[x].Tables[y].Groups.length; z++) {
    //       for (let a = 0; a < workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items.length; a++) {
		// 		  if ((this.tasq?.scheduledJobID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID == this.tasq?.scheduledJobID) || (this.tasq?.scheduledJobID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID == this.tasq?.scheduledJobID) || (this.tasq?.scheduledJobID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID == this.tasq?.scheduledJobID) || (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID == this.tasq?.id) || (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID == this.tasq?.id)) {
		// 			  let isScheduled = false;
		// 			  let startDate = '';
		// 			  let endDate = '';
		// 			  for (let b = 0; b < workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns.length; b++) {
		// 				  if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'STATUS' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response == 'Scheduled') {
    //               isScheduled = true;
		// 				  } else if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'START_DATE' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField) {
    //               startDate = workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response;
		// 				  } else if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'DUE_DATE' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField) {
		// 					  endDate = workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response;
		// 				  }
		// 			  }
		// 			  if (isScheduled) {
    //             var dateObj = new Date(startDate);
    //             var month = dateObj.getUTCMonth() + 1; // months from 1-12
    //             var day = dateObj.getUTCDate();
    //             var year = dateObj.getUTCFullYear();

    //             const newStartDate = `${month}/${day}/${year}`;

    //             var dateObj = new Date(endDate);
    //             var month = dateObj.getUTCMonth() + 1; // months from 1-12
    //             var day = dateObj.getUTCDate();
    //             var year = dateObj.getUTCFullYear();

    //             const newEndDate = `${month}/${day}/${year}`;
		// 				  return `${newStartDate} • ${newEndDate}`;
		// 			  }
		// 		  }
		// 	  	}
		// 	  }
		//   }
	  // }
	  return '';
  }

  getScheduleJobDateString(type) {
	  if (this.tasq?.scheduledJobColumns == null) {
		  return '';
	  }
	  for (let x = 0; x < this.tasq?.scheduledJobColumns.length; x++) {
		  if (this.tasq!.scheduledJobColumns[x].ColumnType == type) {
			  const date_obj = new Date(this.tasq!.scheduledJobColumns[x].Response);
			  date_obj.setDate(date_obj.getDate() + 1);
			  return date_obj.toLocaleDateString('en-US');
		  }
	  }
    //   return date.toISOString().substring(0, 10);
    return '';
  }

  //   get fullName(): string {
  // 	  return getNameByEmail(this.tasq?.username);
  //   }

  getOverridenRealTimeLabel(label) {
	  if (label.toLowerCase() == 'rtd') {
		  return 'Deferment';
	  }
	  if (label.toLowerCase() == 'hiline') {
		  return 'Line Pressure';
	  }
	  return label;
  }

  goToTasqPage() {
    // this.$router.replace({
    //   name: 'Tasqs',
    //   params: {
    //     id: this.tasq?.id || '',
    //   },
    //   query: {
    //     type: 'id',
    //   }
    // });

    tasqsListModule.setActiveTasq(this.tasq?.id || '');
    tasqsListModule.setKanbanSelectedTasq(this.tasq?.id || '');
    this.$emit('set-hide-well');
  }
}

function isTasqActive(id) {
  if (window.location.href.includes(id)) {
    return true;
  }
  return false;
}
